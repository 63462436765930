import html from "html-literal";

export default () => html `
<head>
<meta name="description" content="installerbio, door repair, window repair, window installer, door installer, window part installer, door part installer, handyman, door help, window help, customer feedback, customer, customer reply">
    <meta name="keywords" content="customer, installer bio, installerbio, door installation, window installation, window, door">
    <title>Installer Feedback | InstallerBio</title>
</head>
<div class="container text-center">
   
<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfr5Tls0x_vT8Z-khEwWtVAnegcuJyESn6CugTumC1ZLJUxMw/viewform?embedded=true" width="700" height="1150" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

</div>
`;