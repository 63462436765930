import html from "html-literal";

export default () => html`
<head>
    <title>Thank You for your Submission! | InstallerBio</title>
</head>
<div class="container text-center formPage">
    <h2>Thank you for submitting your form!</h2>
    <p>
    <a href="/Home"><button class="submission">Return Home</button></a>
    </p>
</div>
`;